import "./style.scss";
import ccrLogo from '../../../assets/images/logos/ccr.svg';
import electroLogo from '../../../assets/images/logos/electro-3000.svg';
import React from "react";
import Box from "../../../layout/box";
import StandardInsert from "../../../layout/standard-insert";
import Logos3 from "../../../components/logos/logos-3";

type Props = {

}

const GameEnd: React.FC<Props> = () => {
    return <div className="game-end">
        <Box>
            <StandardInsert>
                <h1>Gratulacje!</h1>
                <p>
                    Dobra robota!
                </p>
                <p>
                    Dzięki Tobie rośliny i zwierzęta są bezpieczne, a Ty możesz rozkoszować się naturalnym pięknem przyrody! Zawsze zabieraj swoje śmieci i segreguj do odpowiednich pojemników lub oddawaj do wyznaczonych punktów zbiórki odpadów! Jeśli chcesz dalej kontynuować przygodę, dołącz do misji Kapitana Ryjówki i Elektro3000 na stronie <a href="//www.niezasmiecamprzyrody.pl">www.niezasmiecamprzyrody.pl</a>! Do zobaczenia na trasie!
                </p>
                <div className="logos">
                    <div className="img">
                        <img src={ccrLogo} alt="" />
                    </div>
                    <div className="img">
                        <img src={electroLogo} alt="" />
                    </div>
                </div>
                <p>
                    Zajrzyj też na <a href="//www.elektro3000.pl">www.elektro3000.pl</a> aby dowiedzieć się więcej o segregacji i recyklingu odpadów.
                </p>
            </StandardInsert>
        </Box>
        <Box className='bg-white no-padding'>
            <Logos3 />
        </Box>
    </div>
}

export default GameEnd;
